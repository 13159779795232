import axios from 'axios';
import { URL_API } from '@/config/api';

export async function checkAccountExists(body) {
  const url = `${URL_API}/integration_accounts/check_account_exists`;
  
  const {data, error} = await axios.post(url, body);
  if(error) {
    return error
  } else {
    return data
  }
}

export async function createAccount(body) {

  const url = `${URL_API}/integration_accounts/create_account`;
  
  const {data} = await axios.post(url, body);
  return data;
}

export async function confirmAccount(body) {

  const url = `${URL_API}/integration_accounts/confirm_account`;
  
  const {data} = await axios.post(url, body);
  return data;
}