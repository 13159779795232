<template>
  <v-card-text>
    <v-form >
      <component :is="currentStepComponent" ref="formRef"></component>
    </v-form>
  </v-card-text>
</template>


<script>
import Step1Component from "@/components/register/Step1Component.vue";
import Step2Component from "@/components/register/Step2Component.vue";
import Step3Component from "@/components/register/Step3Component.vue";

export default {
  props: ["step"],
  components: {
    Step1Component,
    Step2Component,
    Step3Component,
  },
  data() {
    return {};
  },
  computed: {
    currentStepComponent() {
      switch (this.step) {
        case 1:
          return "Step1Component";
        case 2:
          return "Step2Component";
        case 3:
          return "Step3Component";
        default:
          return "Step1Component";
      }
    },
    formRef() {
      switch (this.step) {
        case 1:
          return "step1Form";
        case 2:
          return "step2Form";
        case 3:
          return "step3Form";
        default:
          return null;
      }
    },
  },

  methods: {
    async validate() {
      console.log("validate StepFieldsComponent");
      if (this.$refs.formRef && this.$refs.formRef.validate) {
        let valid = await this.$refs.formRef.validate();
        console.log("valid StepFieldsComponent", valid);
        return Promise.resolve(valid);
      }
      console.log("Erro ao validar formulário");
      return Promise.resolve(false);
    },
  },
};
</script>

<style src="../../styles/Steps.css"></style>
