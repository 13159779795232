import { toast } from 'vue3-toastify';

export function showNotificationSuccess(text) {
    toast(
        text,
        {
            theme: 'colored',
            position: 'top-right',
            type: 'success',
            autoClose: 5000,
            isClosable: true,
            dangerouslyHTMLString: true
        }
    )

}

export function showNotificationError(text) {
    toast(
        text,
        {
            theme: 'colored',
            position: 'top-right',
            type: 'error',
            autoClose: 5000,
            isClosable: true,
            dangerouslyHTMLString: true
        }
    )

}

export function showNotificationWarning(text) {
    toast(
        text,
        {
            theme: 'colored',
            position: 'top-right',
            type: 'warning',
            autoClose: 5000,
            isClosable: true,
            dangerouslyHTMLString: true
        }
    )
}

export function showNotificationInfo(text) {
    toast(
        text,
        {
            theme: 'dark',
            position: 'top-right',
            type: 'info',
            autoClose: 5000,
            isClosable: true,
            dangerouslyHTMLString: true
        }
    )
}