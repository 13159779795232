<template>
  <v-form ref="step1Form">
    <v-row justify="center">
      <v-col cols="12" lg="12" md="10" class="pb-0">
        <div class="field-description"><span>CPF*</span></div>
        <v-text-field
          v-model="formDataStep1.inscription"
          placeholder="Digite aqui seu CPF"
          v-mask="'###.###.###-##'"
          required
          inputmode="numeric"
          variant="outlined"
          :rules="[rules.required, rules.cpfValid]"
          prepend-inner-icon="mdi-numeric"
          color="#0e0d5e"
          maxlength="14"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="12" md="10" class="pb-0 small-col">
        <div class="field-description"><span>NOME*</span></div>
        <v-text-field
          v-model="formDataStep1.name"
          placeholder="Digite seu nome completo"
          prepend-inner-icon="mdi-alphabetical"
          required
          :rules="[rules.required]"
          variant="outlined"
          color="#0e0d5e"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="12" md="10" class="pb-0 small-col">
        <div class="field-description"><span>TELEFONE*</span></div>
        <v-text-field
          v-model="formDataStep1.phone"
          placeholder="(00) 00000-0000"
          prepend-inner-icon="mdi-phone"
          required
          inputmode="numeric"
          v-mask="['(##) ####-####', '(##) #####-####']"
          :rules="[rules.required]"
          variant="outlined"
          color="#0e0d5e"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="12" md="10" class="pb-0 small-col">
        <div class="field-description"><span>DATA DE NASCIMENTO*</span></div>
        <v-row justify="start">
          <v-col cols="4" class="pb-0 small-text">
            <v-text-field
              v-model="formDataStep1.birthdate.day"
              inputmode="numeric"
              placeholder="DIA"
              :prepend-inner-icon="icon"
              required
              :rules="[rules.required, rules.dayValid]"
              variant="outlined"
              color="#0e0d5e"
              maxlength="2"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="pb-0 small-text">
            <v-text-field
              v-model="formDataStep1.birthdate.month"
              inputmode="numeric"
              placeholder="MÊS"
              :prepend-inner-icon="icon"
              required
              :rules="[rules.required, rules.monthValid]"
              variant="outlined"
              color="#0e0d5e"
              maxlength="2"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="pb-0">
            <v-text-field
              v-model="formDataStep1.birthdate.year"
              placeholder="ANO"
              :prepend-inner-icon="icon"
              required
              inputmode="numeric"
              :rules="[rules.required, rules.yearValid]"
              variant="outlined"
              color="#0e0d5e"
              maxlength="4"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import rules from "../../utils/rules";

export default {
  data() {
    return {
      rules: rules,
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
    };
  },
  computed: {
    ...mapState("form", ["formDataStep1"]),
    icon() {
      return window.innerWidth > 600 ? "mdi-calendar" : "";
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    async validate() {
      console.log("validando formulário");
      return await this.$refs.step1Form.validate();
    },
    onResize() {
      this.$forceUpdate();
    },
  },
};
</script>
<style src="../../styles/Steps.css"></style>
