let BASE_URL_API = 'http://localhost:5000'

const isLocalHost = (url) => url.indexOf("127.0.0.1") != -1 || url.indexOf("localhost") != -1 || url.indexOf("192.168") != -1

const isHomolog = (url) => url.indexOf("homolog") != -1

BASE_URL_API = isLocalHost(window.location.href) ? 'http://localhost:5000' 
    :  isHomolog(window.location.href) ? 'https://api.homologacao.sox.sxgrupo.com.br'
    : 'https://api.sox.sxgrupo.com.br';

export const URL_API_SOX = `${BASE_URL_API}`;



