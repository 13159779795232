<template>
  <v-app >
    <NavBar />
    <notifications class="notification" />
    <v-main class="main-init">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavbarComponent.vue';
import { getConfigs } from './services/configs/index';

export default {
  name: "App",
  components: {
    NavBar
  },
  created() {
    const clubDomain = this.getClubDomain();
    getConfigs(clubDomain).then((data) => {
      this.$store.dispatch("configs/setConfigs", { data });
    });
  },
  methods: {
    getClubDomain() {
      const hostname = window.location.hostname;
      const isLocal = hostname.includes("localhost");
      const isHomoloation = hostname.includes("homologacao");
      if (isLocal) {
        return "xtreme";
      }
      if (isHomoloation) {
        return hostname.split(".")[0];
      }
      console.log(hostname);
      const parts = hostname.split(".pokersx.com.br");
      if (parts.length > 1) {
        return parts[0];
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="scss">
.vue-notification {
  padding: 10px;
  margin: 0 5px 5px;
  height: 300px;
  font-size: 12px;
  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }
}

.main-init {
  padding-top: 24px !important;
}
</style>
<style src="./styles/Index.css"></style>
