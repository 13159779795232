import { showNotificationError, showNotificationSuccess } from "@/services/notifications/index.js";
import { sendCodeReset, validateCodeReset, setNewPassword } from "../../services/recover";




const state = {
    currentStep: 1,
    loading: false,
    formDataRecover: {
        email: "",
        password: "",
        confirmationPassword: "",
        token: ["", "", "", "", "", ""]
    },
};

const getters = {
    // getters if needed
}

const mutations = {
    SET_SHOW_STEPS(state, value) {
        state.showSteps = value;
    },

    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_NEXT_CURRENT_STEP(state) {
        state.currentStep = state.currentStep + 1;
    },



    SET_PREVIOUS_CURRENT_STEP(state) {
        state.currentStep = state.currentStep - 1;
    },

    SET_CURRENT_STEP(state, step) {
        state.currentStep = step;
    },


};

const actions = {
    async sendCode({ state, commit }) {
        commit("SET_LOADING", true);
        let data = {
            email: state.formDataRecover.email,
        }
        try {
            const response = await sendCodeReset(data);
            commit("SET_LOADING", false);
            commit("SET_NEXT_CURRENT_STEP");
            return response;
        } catch (error) {
            commit("SET_LOADING", false);
            showNotificationError("Erro ao enviar código, confira o email informado e tente novamente");
        }
    },
    async validateCode({ state, commit }) {
        commit("SET_LOADING", true);
        let data = {
            email: state.formDataRecover.email,
            code: state.formDataRecover.token.join(""),
        }
        try {
            const response = await validateCodeReset(data);
            commit("SET_LOADING", false);
            commit("SET_NEXT_CURRENT_STEP");
            return response;
        } catch (error) {
            console.log("error", error.response.data.message);
            let message = error.response.data.message == 'Token has expired or is invalid' ? 'Código inválido ou expirado' : 'Ocorreu um erro ao validar o código, tente novamente';
            commit("SET_LOADING", false);
            showNotificationError(message);
        }
    },
    async setPassword({ state, commit }, router) {
        commit("SET_LOADING", true);
        let data = {
            email: state.formDataRecover.email,
            password: state.formDataRecover.password,
            password_confirm: state.formDataRecover.confirmationPassword
        }
        try {
            const response = await setNewPassword(data);
            commit("SET_LOADING", false);
            showNotificationSuccess("Senha alterada com sucesso");
            await new Promise((resolve) => setTimeout(resolve, 2000));
            router.push({ name: "login" });
            commit("SET_CURRENT_STEP", 1);
            return response;
        } catch (error) {
            console.log("error", error);
            commit("SET_LOADING", false);
            showNotificationError("Erro ao salvar senha, tente novamente");
        }
    },
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
