<template>
  <div
    class="login"
    :style="{ backgroundImage: `url(${style.backgroundImg})` }"
  >
    <v-card class="form-card-login">
      <v-card-title class="mt-1 d-flex justify-center align-center">
        <img src="../assets/fichasXtreme.png" alt="" class="img-login" />
      </v-card-title>
      <v-form ref="form">
        <v-card-text class="form-content-login">
          <v-container class="box-login pt-0">
            <p class="text-login">LOGIN</p>
            <span class="login-field mt-2 mb-2 ml-1">E-mail</span>
            <v-row>
              <v-col cols="10" lg="12" md="11" sm="10">
                <v-text-field
                  v-model="formData.username"
                  placeholder="exemplo@email.com"
                  required
                  rounded
                  variant="outlined"
                  max-width="860px"
                  :rules="[rules.required]"
                  color="#0e0d5e"
                ></v-text-field>
              </v-col>
            </v-row>
            <span class="login-field mt-2 mb-2 ml-1">Senha</span>
            <v-row>
              <v-col cols="10" lg="12" md="11" sm="11">
                <v-text-field
                  v-model="formData.password"
                  placeholder="Sua senha"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showPassword = !showPassword"
                  :rules="[rules.required]"
                  :type="showPassword ? 'text' : 'password'"
                  rounded
                  max-width="860px"
                  variant="outlined"
                  color="#0e0d5e"
                ></v-text-field>
              </v-col>
            </v-row>

            <span class="login-field mt-1 ml-1"
              ><a
                href="/recover-password"
                style="text-decoration: none; color: #0d3a50"
                >Esqueceu a senha ?</a
              ></span
            >
          </v-container>
        </v-card-text>
      </v-form>
      <v-card-actions class="button-margin pt-0 mt-1">
        <button v-if="!loadingStep" @click="tryLogin" class="button-next">
          <div class="text-wrapper">ENTRAR</div>
        </button>

        <v-progress-circular
          v-else
          indeterminate
          color="primary"
          class="loading-spinner"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>
  
<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import rules from "../utils/rules";

export default {
  components: {},
  computed: {
    ...mapState("configs", ["style"]),
    ...mapFields("login", ["loading", "formData", "stayConnected"]),
  },
  data() {
    return {
      totalSteps: 5,
      instructionText: "Texto com orientações ao usuário",
      rules: rules,
      showPassword: false,
    };
  },
  methods: {
    ...mapActions("login", ["login"]),
    async tryLogin() {
      let valid = await this.$refs.form.validate();
      if (valid.valid) {
        this.login();
      }
    },
  },
};
</script>
  
<style src="../styles/Login.css"></style>