<template>
  <div
    v-for="iconNumber in iconNumbers"
    :key="iconNumber"
    class="box-container"
  >
    <div class="box">
      <div class="ellipse" :style="getEllipseStyle(iconNumber)">
        <div
          :class="'svg-icon-' + iconNumber"
          :style="getSvgStyle(iconNumber)"
        ></div>
      </div>
    </div>
    <div v-if="iconNumber < 6" class="box-line">
      <div class="line" :style="getLineStyle(iconNumber)"></div>
    </div>
  </div>
</template>


<script>
export default {
  name: "StepperComponent",
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      iconNumbers: [1, 2, 3, 4, 5, 6],
    };
  },
  computed: {
    currentStep() {
      return this.step;
    },
  },
  methods: {
    getEllipseStyle(i) {
      return i <= this.step
        ? {
            background:
              "linear-gradient(180deg, rgb(79, 172, 254) 0%, rgb(0, 242, 254) 100%)",
          }
        : { "background-color": "#c1c1c1" };
    },
    getLineStyle(i) {
      return i <= this.step - 1
        ? {
            background:
              "linear-gradient(180deg,rgb(79, 172, 254) 0%,rgb(0, 242, 254) 100%)",
          }
        : { "background-color": "#dddddd" };
    },
    getSvgStyle(i) {
      return i <= this.step
        ? { "background-color": "#0d3a50" }
        : { "background-color": "#595959" };
    },
  },
};
</script>
<style src="../styles/Stepper.css"></style>