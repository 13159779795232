


import { createBuyChips } from "@/services/chips/index.js";
import { showNotificationError } from "@/services/notifications/index.js";


const state = {
    showSteps: false,
    formDataBuyChips: {
        valueChips: 50
    },
    currentStep: 5,
    loading: false,
    qrCode: {
        text: "",
        hash: "",
        idCob: ""
    },

};

const getters = {
    // getters if needed
};

const mutations = {
    SET_SHOW_STEPS(state,value) {
        state.showSteps = value;
    },

    SET_LOADING(state, loading) {
        state.loading = loading;
    },

    SET_NEXT_CURRENT_STEP(state) {
        state.currentStep = state.currentStep + 1;
    },



    SET_PREVIOUS_CURRENT_STEP(state) {
        state.currentStep = state.currentStep - 1;
    },

    SET_CURRENT_STEP(state, step) {
        state.currentStep = step;
    },

    SET_QR_CODE(state, payload) {
        state.qrCode.text = payload.text;
        state.qrCode.hash = payload.hash;
        state.qrCode.idCob = payload.id_cob;
    },


};

const actions = {
    async saveStepNonValidation({ commit }) {
        commit("SET_NEXT_CURRENT_STEP");
    },
    async buyChips({ state, commit, rootState }) {

        commit("SET_LOADING", true)
        const idUserAccount = rootState.login.idUserAccount;
        let value = state.formDataBuyChips.valueChips.toString().replace(',', '.');
        let data = {
            idUserAccount: idUserAccount,
            value: value
        }
        try {
            let result = await createBuyChips(data);
            commit("SET_LOADING", false)
            const qrCodeData = {
                text: result.qrcode.text,
                hash: result.hash,
                id_cob: result.qrcode.idCob
            }
            commit("SET_QR_CODE", qrCodeData)
            commit("SET_NEXT_CURRENT_STEP")

        } catch (error) {
            console.error('Erro ao comprar fichas:', error);
            commit("SET_LOADING", false)
            commit("SET_QR_CODE", { text: "", hash: "" })
            commit("SET_CURRENT_STEP", 4);
            showNotificationError('Erro ao comprar fichas. Tente novamente mais tarde.')
        }

    },
    finishPurchase({ commit }) {
        commit("SET_QR_CODE", { text: "", hash: "" })
        commit("SET_NEXT_CURRENT_STEP")
    }
};




export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
