<template>
  <div class="menu" :style="{ backgroundImage: `url(${style.backgroundImg})` }">
    <v-card class="form-card-menu">
      <v-card-title class="title-menu d-flex justify-center align-center">
        <p class="text-menu mb-5">O QUE DESEJA FAZER?</p>
      </v-card-title>
      <v-card-text class="form-content-menu">
        <v-row class="row-actions mb-8">
          <v-col cols="12" md="6" sm="6" xs="3">
            <v-sheet
              border="lg opacity-12"
              rounded="xl"
              max-width="610"
              max-height="300"
              class="sheet-actions mx-auto px-4"
              @click="goToWithdraw"
            >
              <v-container fluid>
                <div>
                  <img
                    src="../assets//withdrawChips.svg"
                    class="img-actions mb-2"
                    alt=""
                  />
                </div>

                <p class="text-action">Retirar Fichas</p>
              </v-container>
            </v-sheet>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="3">
            <v-sheet
              border="lg opacity-12"
              rounded="xl"
              max-width="610"
              max-height="300"
              class="mx-auto px-4 sheet-actions"
              @click="goToDeposit"
            >
              <v-container fluid>
                <div>
                  <img
                    src="../assets//depositChips.svg"
                    class="img-actions mb-2"
                    alt=""
                  />
                </div>
                <p class="text-action">Comprar Fichas</p>
              </v-container>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row class="row-img mt-10">
          <v-col cols="12">
            <img src="../assets/fichasXtreme.png" alt="" class="img-menu" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>
import { mapMutations, mapState } from "vuex";

export default {
  components: {},
  computed: {
    ...mapState("configs", ["style"]),
  },
  data() {},
  methods: {
    ...mapMutations("purchase", {
      setCurrentStepPurchase: "SET_CURRENT_STEP",
    }),
    ...mapMutations("withdraw", {
      setCurrentStepWithdraw: "SET_CURRENT_STEP",
    }),
    goToWithdraw() {
      this.setCurrentStepWithdraw(1)
      this.$router.push("/withdraw");
    },
    goToDeposit() {
      this.setCurrentStepPurchase(4)
      this.$router.push("/buy-chips");
    },
  },
};
</script>

<style src="../styles/Menu.css">

</style>