<template>
    <v-form >
      <component :is="currentStepComponent"></component>
    </v-form>
</template>


<script>
import BuyChipsComponent from "@/components/purchase/BuyChipsComponent.vue";
import QrCodeComponent from "@/components/purchase/QrCodeComponent.vue";
import PaymentConfirmationComponent from "@/components/purchase/PaymentConfirmationComponent.vue";

export default {
  props: ["step"],
  components: {
    BuyChipsComponent,
    QrCodeComponent,
    PaymentConfirmationComponent,
  },
  data() {
    return {};
  },
  computed: {
    currentStepComponent() {
      switch (this.step) {
        case 4:
          return "BuyChipsComponent";
        case 5:
          return "QrCodeComponent";
        case 6:
          return "PaymentConfirmationComponent";
        default:
          return "BuyChipsComponent";
      }
    },
  },

  methods: {},
};
</script>

