import { login, refreshSession } from "@/services/login"
import { getField, updateField } from "vuex-map-fields"
import { showNotificationError } from "@/services/notifications/index.js";
import { errors } from "@/utils/errors";
import { getUserPlataformsAccounts } from "@/services/user_plataforms_accounts";
import router from "@/router";

const localStoraToken = 'userLogged'

const loginModule = {
    namespaced: true,
    state: {
        club: "",
        formData: {
            username: "",
            password: "",
        },
        idUserAccount: null,
        playerId: null,
        idClub: null,
        platformId: null,
        userLogged: null,
        logged: false,
        stayConnected: true,
        firtsAccess: false
    },
    getters: {
        isLogged(state) {
            return state.userLogged !== null && state.logged === true
        },
        isTokenExpired(state) {
            let now = new Date().getTime()
            return state.userLogged.expiresAt > now
        },
        stayConnected: state => state.stayConnected,
        getField
    },
    mutations: {
        SET_USER_LOGGED(state, payload) {
            state.userLogged = payload.user;
            state.logged = payload.logged;
            localStorage.setItem(localStoraToken, JSON.stringify(payload.user))
            console.log("user setado")
        },
        SET_FIRST_ACCESS(state) {
            state.firtsAccess = true
        },
        SET_ID_USER_ACCOUNT(state, id) {
            state.idUserAccount = id;
        },
        SET_PLAYER_ID(state, player_id) {
            state.playerId = player_id;
        },
        SET_ID_CLUB(state, id_club) {
            state.idClub = id_club;
        },
        SET_CLUB(state, club) {
            state.club = club;
        },
        SET_PLATFORM_ID(state, platform_id) {
            state.platformId = platform_id;
        },

        updateField
    },
    actions: {
        async logout({ commit }) {
            console.log("LOGOUT")
            try {
                commit('SET_USER_LOGGED', { user: null, logged: false });
                commit('SET_ID_USER_ACCOUNT', null);
                commit('SET_PLAYER_ID', null);
                commit('SET_ID_CLUB', null);
                commit('SET_PLATFORM_ID', null);

                localStorage.removeItem('userLogged');

                router.push({ name: 'login' });
            } catch (error) {
                console.error('Erro ao fazer logout:', error);
                showNotificationError('Erro ao fazer logout. Tente novamente mais tarde.');
            }
        },
        
        async login({ commit, state }) {
            console.log("LOGIN")

            try {
                let result = await login(state.formData)
                console.log(state.formData)
                result.stayConnected = state.stayConnected
                commit('SET_USER_LOGGED', { user: result, logged: true })
                let { data } = await getUserPlataformsAccounts()
                if (data.length > 0) {
                    commit('SET_ID_USER_ACCOUNT', data[0].id)
                    commit('SET_PLAYER_ID', data[0].playerId)
                    commit('SET_ID_CLUB', data[0].idClub)
                    commit('SET_CLUB', data[0].club)
                    commit('SET_PLATFORM_ID', data[0].platformId)
                    router.push({ name: 'menu' })
                }
            } catch (error) {
                let message = error?.response?.data?.message || "Erro ao fazer login"
                if (errors[message]) message = errors[message]
                showNotificationError(message)
            }
        },
        async recoverToken({ commit, dispatch }) {
            console.log("recoverToken")

            let user = localStorage.getItem(localStoraToken)
            if (user) {
                user = JSON.parse(user)
                if (new Date().getTime() / 1000 < user.expiresAt) {
                    if (user.stayConnected) {
                        await dispatch('refreshToken', { refreshToken: user.refreshToken })
                    } else {
                        dispatch('unauthorized')
                    }
                } else {
                    await commit('SET_USER_LOGGED', { user: user, logged: true })
                    let { data } = await getUserPlataformsAccounts()
                    if (data.length > 0) {
                        await commit('SET_ID_USER_ACCOUNT', data[0].id)
                        await commit('SET_PLAYER_ID', data[0].playerId)
                        await commit('SET_ID_CLUB', data[0].idClub)
                        await commit('SET_CLUB', data[0].club)
                        await commit('SET_PLATFORM_ID', data[0].platformId)
                        router.push({ name: 'menu' })
                    }
                }
            }
        },
        async refreshToken({ commit, state }, payload) {
            console.log("refreshToken")

            try {
                let result = await refreshSession(payload.refreshToken)
                await commit('SET_USER_LOGGED', { user: result, logged: true, stayConnected: state.stayConnected })
                let { data } = await getUserPlataformsAccounts()
                if (data.length > 0) {
                    console.log("ok")
                    await commit('SET_ID_USER_ACCOUNT', data[0].id)
                    await commit('SET_PLAYER_ID', data[0].playerId)
                    await commit('SET_ID_CLUB', data[0].idClub)
                    await commit('SET_CLUB', data[0].club)
                    await commit('SET_PLATFORM_ID', data[0].platformId)
                    router.push({ name: 'menu' })
                }
            } catch (error) {
                console.log("eerrorr")
                commit('SET_USER_LOGGED', { user: null, logged: false })
                localStorage.removeItem(localStoraToken)
                router.push({ name: 'login' })
            }
        },
        async unauthorized({ commit }) {
            console.log("unauthorized")

            localStorage.removeItem(localStoraToken)
            commit('SET_USER_LOGGED', { user: null, logged: false })
            router.push({ name: 'login' })
        }
    },
}
export default loginModule