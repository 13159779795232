import axios from 'axios';
import { URL_API } from '@/config/api';

export async function login(body) {

  const url = `${URL_API}/login/user`;
  
  const { data } = await axios.post(url, body);
  return data;
}

export async function refreshSession(refreshToken) {
  
    const url = `${URL_API}/login/refreshSession/${refreshToken}`;
    
    const { data } = await axios.put(url, { refreshToken });
    return data;
}