<template>
  <div class="loading">
    <p>Carregando...</p>
    <Spinner />
    <div class="logos">
      <img src="@/assets/logoFichas.png" alt="Logo Fichas 24 horas" />
      <img src="@/assets/logoSuprema.png" alt="Logo Suprema Poker" />
    </div>
  </div>
</template>

<script>
import { getConfigs } from "../services/configs/index";
import Spinner from "@/components/SpinnerLoading.vue";
export default {
  name: "LoadingPage",
  components: {
    Spinner,
  },
  created() {
    const clubDomain = this.getClubDomain();
    // Faz a requisição para obter os estilos do site
    getConfigs(clubDomain).then((data) => {
      this.$store.dispatch("configs/setConfigs", {
        data,
      });
      this.$router.push("/register");
    });
  },

  methods: {
    getClubDomain() {
      const hostname = window.location.hostname;
      const isLocal = hostname.includes("localhost");
      const isHomoloation = hostname.includes("homologacao");
      if (isLocal) {
        return "xtreme";
      }
      if (isHomoloation) {
        return hostname.split(".")[0];
      }
      const parts = hostname.split(".pokersx.com.br");
      if (parts.length > 1) {
        return parts[0];
      } else {
        return "";
      }
    },
  },
};
</script>


<style src="../styles/Loading.css"></style>