<template>
  <v-container class="form-container-recover mb-16">
    <v-card class="form-card-recover px-1">
      <v-card-title class="form-header-recover pb-1">
        <v-btn  icon @click="goBack" class="back-button">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="closeModal"
          class="close-button"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-card-text class="form-content-recover">
        <StepsRecoverComponent :step="currentStep" />
      </v-card-text>
    </v-card>
  </v-container>
</template>




<script>
import { mapMutations, mapState } from "vuex";
import StepsRecoverComponent from "./StepsRecoverComponent.vue";

export default {
  props: {},
  data() {
    return {};
  },
  components: {
    StepsRecoverComponent,
  },
  computed: {
    ...mapState("recover", ["showSteps", "currentStep"]),
    ...mapState("login", ["userLogged"]),
  },
  created() {
   console.log(this.currentStep);
  },
  methods: {
    ...mapMutations("recover", [
      "SET_PREVIOUS_CURRENT_STEP",
      "SET_CURRENT_STEP",
    ]),

    goBack() {
      if (this.currentStep > 1) {
        this.SET_PREVIOUS_CURRENT_STEP(this.currentStep - 1);
      }else{
        this.$router.push("/login");
      }
    },
    closeModal() {
      this.$router.push("/login");
      this.SET_CURRENT_STEP(1);
    },
  },
};
</script>

<style src="../../styles/Recover.css"></style>

