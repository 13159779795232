const state = {
  style:{
    backgroundColor: '',
    logoUrl: '',
    bannerUrl: '',
    bannerMobileUrl: '',
    backgroundImg: '',
    urlBucketAws:''

  },
  config:{
    app: '',
    agent: 0,
    approve: false,
    createFichasAccount: false,
    emailConfirmationFichas: false,
    emailConfirmationApp: false,
    club: 0
  }
};

const mutations = {
  SET_BACKGROUND_COLOR(state, color) {
    state.style.backgroundColor = color;
  },
  SET_BACKGROUND_IMG(state) {
    state.style.backgroundImg = `${state.style.urlBucketAws}/background.png`;
  },
  SET_URL_BUCKET_AWS(state, url) {
    state.style.urlBucketAws = url;
  },
  SET_LOGO_URL(state) {
    state.style.logoUrl = `${state.style.urlBucketAws}/logo.png`;
  },
  SET_BANNER_URL(state) {
    state.style.bannerUrl = 'https://styles-clubs.s3.sa-east-1.amazonaws.com/bannerRegister.png';
  },
  SET_BANNER_MOBILE_URL(state) {
    state.style.bannerMobileUrl = 'https://styles-clubs.s3.sa-east-1.amazonaws.com/bannerMobile1.png';
  },
  SET_APP(state, value) {
    state.config.app = value;
  },
  SET_AGENT(state, value) {
    state.config.agent = value;
  },
  SET_APPROVE(state, value) {
    state.config.approve = value;
  },
  SET_CREATE_FICHAS_ACCOUNT(state, value) {
    state.config.createFichasAccount = value;
  },
  SET_EMAIL_CONFIRMATION_FICHAS(state, value) {
    state.config.emailConfirmationFichas = value;
  },
  SET_EMAIL_CONFIRMATION_APP(state, value) {
    state.config.emailConfirmationApp = value;
  },
  SET_CLUB(state, value) {
    state.config.club = value;
  }
};

const getters = {
  getConfig(state) {
    return state.config;
  },
  getStyle(state) {
    return state.style;
  }
};

const actions = {
  setConfigs({ commit }, { data }) {
    commit('SET_URL_BUCKET_AWS', data.urlBucketAws);
    commit('SET_BACKGROUND_COLOR', data.style.backgroundColor);
    commit('SET_LOGO_URL',);
    commit('SET_BACKGROUND_IMG');
    commit('SET_APP', data.app);
    commit('SET_AGENT', data.agent);
    commit('SET_APPROVE', data.approve);
    commit('SET_CREATE_FICHAS_ACCOUNT', data.createFichasAccount);
    commit('SET_EMAIL_CONFIRMATION_FICHAS', data.emailConfirmationFichas);
    commit('SET_EMAIL_CONFIRMATION_APP', data.emailConfirmationApp);
    commit('SET_BANNER_URL');
    commit('SET_BANNER_MOBILE_URL');
    commit('SET_CLUB', data.club);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
