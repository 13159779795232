<template>
  <v-form ref="formRecoverVerify" v-model="isValid" lazy-validation>
    <v-row>
      <v-col cols="12" style="margin: auto">
        <p class="text-recover">
          <span class="extra-bold">VERIFICAÇÃO</span>
        </p>
        <p class="subtext-recover mt-4">
          Enviamos um código para o e-mail:
          <span class="extra-bold">{{ formDataRecover.email }}</span>
        </p>
      </v-col>

      <v-col
        v-for="(number, index) in formDataRecover.token"
        :key="index"
        cols="2"
        class="col-code"
      >
        <v-text-field
          class="code-field-recover  mb-1"
          bg-color="#E8E8E8"
          color="#0e0d5e"
          variant="outlined"
          inputmode="numeric"
          v-model="formDataRecover.token[index]"
          ref="codeFields"
          @input="focusNextField(index)"
          maxlength="1"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <button @click="pasteCode" class="paste-code mb-4">
        <i class="mdi mdi-content-paste"></i> COLAR
      </button>
    </v-row>
    <div class="warning-recover mt-4">
      <p class="text-warning-recover mb-7">
        <span>
          ⚠️ <strong class="extra-bold">AVISO</strong>: Caso ainda não tenha
          recebido, verifique em <strong class="extra-bold">SPAM</strong>, ou
          <a href="google.com" class="extra-bold"> CLIQUE AQUI </a> para
          reenviar!
        </span>
        <br />
      </p>
    </div>
    <v-row>
      <v-col cols="6" offset="3" style="text-align: center" class="pt-0">
        <button v-if="!loading" @click="verify()" class="button-next">
          <div class="text-wrapper">CONTINUAR</div>
        </button>
        <v-progress-circular
          v-else-if="loading"
          indeterminate
          color="#0D3A50"
          :size="33"
          class="loading-spinner"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import rules from "../../utils/rules";
import { showNotificationError } from "@/services/notifications/index.js";
export default {
  data() {
    return {
      rules: rules,
      isValid: false,
    };
  },
  computed: {
    ...mapState("recover", ["formDataRecover", "loading"]),
    ...mapFields("recover", ["formDataRecover.token", "formDataRecover.email"]),
    ...mapState("login", ["userLogged"]),
  },
  components: {
    // Spinner,
  },
  methods: {
    pasteCode() {
      navigator.clipboard
        .readText()
        .then((text) => {
          text = text.trim();
          if (text.trim().length === 6 && /^\d+$/.test(text)) {
            this.formDataRecover.token = text.split("");
          } else {
            showNotificationError(
              "O código colado não está no formato correto. Por favor, tente novamente."
            );
          }
        })
        .catch((err) => {
          console.error("Erro ao acessar a área de transferência: ", err);
          showNotificationError(
            "Não foi possível acessar a área de transferência. Por favor, tente novamente."
          );
        });
    },
    handlePaste(event) {
      if (event.ctrlKey && (event.key === "v" || event.key === "V")) {
        event.preventDefault();
        this.pasteCode();
      }
    },
    ...mapActions("recover", ["validateCode"]),
    focusNextField(index) {
      if (index < 5 && this.formDataRecover.token[index].length === 1) {
        const nextField = this.$refs.codeFields[index + 1];
        if (nextField) {
          nextField.focus();
        }
      }
    },

    async verify() {
      await this.$refs.formRecoverVerify.validate();
      if (this.isValid) {
        this.validateCode();
      } else {
        showNotificationError("Preencha o campo de código corretamente");
      }
    },
  },
};
</script>
<style src="../../styles/Recover.css"></style>