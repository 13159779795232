import { createRouter, createWebHistory } from 'vue-router'
import LoadingPage from '../components/LoadingPage.vue'
import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
import RegisterFormView from '@/views/RegisterFormView.vue'
import PurchaseChipsView from '@/views/PurchaseChipsView.vue'
import store from '@/store'
import WithdrawView from '@/views/WithdrawView.vue'
import MenuView from '@/views/MenuView.vue'
import RecoverPasswordView from '@/views/RecoverPasswordView.vue'

const routes = [
  {
    path: '/',
    redirect: '/loading'
  },
  {
    path: '/loading',
    name: 'loading',
    component: LoadingPage,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/register-form',
    name: 'register-form',
    component: RegisterFormView,
  },
  {
    path: '/buy-chips',
    name: 'buy-chips',
    component: PurchaseChipsView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: WithdrawView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuView,
    meta: {
      requiresAuth: true,
    }

  },
  {
    path: '/recover-password',
    name: 'recover',
    component: RecoverPasswordView,

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log("Roteamento iniciado para:", to.name);
  if (to.meta.requiresAuth && !to.query.skipAuth) {
    let logged = store.getters["login/isLogged"]
    console.log('to aqui 0', logged)
    if (logged) {
      if (!store.getters["login/isTokenExpired"]) {
        next()
      } else if (store.getters["login/stayConnected"]) {
        store.dispatch("login/refreshToken", { route: to })
      } else {
        store.dispatch("login/unauthorized")
      }
    } else {
      await store.dispatch("login/recoverToken")
      let logged = store.getters["login/isLogged"]
      console.log('to aqui 2', logged)
      if (store.getters["login/isLogged"]) {
        next()
      } else {
        router.push({ name: 'login' })
      }
    }
  } else {
    next()
  }
})

export default router
