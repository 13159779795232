import { URL_API } from '@/config/api';
import { URL_API_SOX } from '@/config/apiSox';
import axiosAuth from '@/utils/axios';
import axios from 'axios';
import { TYPE_ACCOUNT_CPF, TYPE_ACCOUNT_CNPJ, TYPE_ACCOUNT_EMAIL, TYPE_ACCOUNT_PHONE, TYPE_ACCOUNT_RANDOM } from "@/consts/withdraw.js";

export async function getKeysPixApi(idUser) {
    const url = `${URL_API}/user_pix/user/${idUser}`;
    let response = await axiosAuth.get(url, { idUser });
    return response.data;

}

export async function createKeyPix(data) {

    const url = `${URL_API}/user_pix`;

    axiosAuth.post(url, data).then(response => {
        console.log(response);
        return response;
    }).catch(error => {
        console.log(error);
        return error;
    });


}

export async function createWithdrawSox(data) {
    const url = `${URL_API_SOX}/withdraw`;
    let response = await axios.post(url, data);
    return response.data;
}

export function getTypeAccount(key) {
    if (key === TYPE_ACCOUNT_CPF) {
        return "CPF"
    } else if (key === TYPE_ACCOUNT_CNPJ) {
        return "CNPJ"
    } else if (key === TYPE_ACCOUNT_EMAIL) {
        return "E-MAIL"
    } else if (key === TYPE_ACCOUNT_PHONE) {
        return "PHONE"
    } else if (key === TYPE_ACCOUNT_RANDOM) {
        return "RANDOM"
    }
}