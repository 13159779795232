import { PLATFORM_CACHETA, PLATFORM_PPPOKER, PLATFORM_SUPREMA } from "@/consts/platforms"

export function getPlatform(platform) {
    if (platform === PLATFORM_SUPREMA) {
        return "Suprema Poker"
    } else if (platform === PLATFORM_PPPOKER) {
        return "PPPoker"
    } else if (platform === PLATFORM_CACHETA) {
        return "Cacheta League Brazil"
    }
   
}
