<template>
  <v-row justify="center">
    <v-col cols="12" md="9">
      <p class="text-register-key">CADASTRAR CHAVE PIX</p>
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-col cols="12" md="9">
      <p class="subtext-register-key">Dados da conta</p>
    </v-col>
  </v-row>
  <v-form ref="RegisterKeyForm">
    <v-row justify="center">
      <v-col cols="10" lg="10" md="8" class="pb-0">
        <div class="field-description"><span>TIPO DE CHAVE*</span></div>
        <v-select
          v-model="formDataRegisterKey.type"
          :items="typesKeys"
          item-title="text"
          item-value="value"
          placeholder="Selecione o tipo de chave"
          prepend-inner-icon="mdi-key"
          variant="outlined"
          :rules="[rules.required]"
          required
          color="#0e0d5e"
        ></v-select>
      </v-col>
      <v-col cols="10" lg="10" md="8" class="pb-0 small-col">
        <div class="field-description"><span>CHAVE*</span></div>
        <v-text-field
          v-model="formDataRegisterKey.key"
          placeholder="Ex.: 00000000000"
          prepend-inner-icon="mdi-key"
          :rules="[rules.required]"
          required
          variant="outlined"
          color="#0e0d5e"
        ></v-text-field>
      </v-col>
      <v-col cols="10" lg="10" md="8" class="pb-0 small-col">
        <div class="field-description"><span>NOME DA CHAVE PIX*</span></div>
        <v-text-field
          v-model="formDataRegisterKey.name"
          placeholder="Ex.: PIX NUBANK"
          prepend-inner-icon="mdi-alphabetical"
          :rules="[rules.required]"
          required
          variant="outlined"
          color="#0e0d5e"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="warning-step mt-4">
      <p class="text-warning mb-4">
        <span>
          ⚠️ Só é possível cadastrar chaves que pertençam ao titular da conta.
        </span>
        <br />
      </p>
    </div>
    <v-row >
      <v-col cols="12">
        <button
          v-if="!loadingStep"
          @click="saveKey"
          class="button-account-create"
        >
          <div class="text-wrapper-account">CONTINUAR</div>
        </button>
        <v-progress-circular
          v-else-if="loadingStep"
          indeterminate
          color="primary"
          class="loading-spinner"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import rules from "../../utils/rules";
import { mapFields } from "vuex-map-fields";

export default {
  data() {
    return {
      rules: rules,
      typesKeys: [
        { text: "CPF", value: "CPF" },
        { text: "CNPJ", value: "CNPJ" },
        { text: "E-mail", value: "EMAIL" },
        { text: "Telefone", value: "PHONE" },
        { text: "Aleatória", value: "EVP" },
      ],
    };
  },
  computed: {
    ...mapState("withdraw", ["formDataRegisterKey", "loading"]),
    ...mapFields("withdraw", [
      "formDataRegisterKey.type",
      "formDataRegisterKey.key",
      "formDataRegisterKey.name",
    ]),
  },

  methods: {
    ...mapActions("withdraw", ["saveKeyPix"]),

    async saveKey() {
      let validation = await this.validate();

      if (validation.valid) {
        this.saveKeyPix();
      }
    },
    async validate() {
      console.log("validando formulário");
      return await this.$refs.RegisterKeyForm.validate();
    },
  },
};
</script>
<style >
.text-register-key {
  text-align: center;
  font-size: 32px;
  color: #0d3a50;
  font-weight: 900;
}
.subtext-register-key {
  text-align: center;
  font-size: 26.45px;
  color: #0d3a50;
  font-weight: 900;
}
.button-account-create {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(79, 172, 254) 0%,
    rgb(0, 242, 254) 100%
  );
  background-color: rgba(255, 255, 255, 1);
  border-radius: 28.98px;
  display: inline-flex;
  gap: 5.8px;
  height: 50.54px;
  justify-content: center;
  padding: 5.8px 17.39px;
  position: relative;
}

.button-account-create:hover {
  filter: drop-shadow(0px 0px 4px #00fff8);
}

.button-account-create .text-wrapper-account {
  color: #0d3a50;
  font-family: "Manrope-ExtraBold", Helvetica;
  font-size: 29px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.1px;
  position: relative;
  width: fit-content;
}

.text-warning {
  font-size: 13.6px;
  color: #929292;
  font-weight: 900;
}

@media (max-width: 1280px) {
}
@media (max-width: 960px) {
  .text-register-key {
    font-size: 28px;
  }
  .subtext-register-key {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .text-register-key {
    font-size: 24px;
  }
  .subtext-register-key {
    font-size: 18px;
  }
}
</style>
