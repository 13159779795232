<template>
  <v-row class="image-info-qr-code">
    <v-col cols="12" sm="6" class="col-img-qr ml-10">
      <div>
        <img class="img-fichas-qr-code" src="../../assets/fichas.svg" alt="" />
      </div>
      <v-card elevation="10" class="info-qr-code mt-6 pa-4">
        <p>
          <strong>Nome:</strong>
          {{ userLogged === null ? "usuário1234" : userLogged.name }}
        </p>
        <p>
          <strong>ID Player:</strong>
          {{ playerId || "123456" }}
        </p>
        <p>
          <strong>Valor:</strong>
          R$ {{ formDataBuyChips.valueChips }}
        </p>
      </v-card>
    </v-col>
    <v-col cols="12" sm="5" class="qr-button-column">
      <qrcode-vue :value="qrCode.text" :size="qrCodeSize" />
      <p class="text-qr-code mt-1">
        LEIA O QR CODE PARA REALIZAR O PAGAMENTO OU COPIE O CÓDIGO PIX:
      </p>
      <button  class="copy-qr-code-button mt-3" @click="copyQrText($event)">
        <i class="mdi mdi-content-paste"></i> COPIAR CÓDIGO
      </button>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";
import rules from "../../utils/rules";
import QrcodeVue from "qrcode.vue";
import copyToClipboard from "copy-to-clipboard";
import {
  setActionFromReceivedMessage,
  startSocket,
} from "../../services/socket/index.js";
import {
  showNotificationError,
  showNotificationInfo,
  showNotificationSuccess,
} from "@/services/notifications/index.js";

// import { simulatePayment } from "@/services/simulate_payment/index.js";

export default {
  data() {
    return {
      rules: rules,
      qrCodeSize: 0,
    };
  },
  mounted() {
    this.updateQrCodeSize();
    window.addEventListener("resize", this.updateQrCodeSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateQrCodeSize);
  },
  created() {
    if (this.qrCode.hash) {
      startSocket(this.qrCode.hash);
    }
    setActionFromReceivedMessage(this.handleNotification);
  },
  components: {
    QrcodeVue,
  },
  computed: {
    ...mapState("purchase", ["qrCode", "formDataBuyChips"]),
    ...mapState("login", ["userLogged", "playerId"]),
  },
  methods: {
    ...mapActions("purchase", ["finishPurchase"]),
    updateQrCodeSize() {
      const xs = 600;
      const sm = 960;
      const md = 1280;
      const lg = 1920;

      const width = window.innerWidth;

      if (width < xs) {
        this.qrCodeSize = 130; // Defina o tamanho para xs
      } else if (width < sm) {
        this.qrCodeSize = 200; // Defina o tamanho para sm
      } else if (width < md) {
        this.qrCodeSize = 300; // Defina o tamanho para md
      } else if (width < lg) {
        this.qrCodeSize = 350; // Defina o tamanho para lg
      } else {
        this.qrCodeSize = 350; // Defina o tamanho para xl
      }
    },
    handleNotification(data) {
      if (data.type === "notification") {
        if (data.success) {
          if (data.success == false) {
            showNotificationError(data.message);
          }
        } else {
          showNotificationInfo(data.message);
        }
      } else {
        if (data.pay == true) {
          this.finishPurchase(data);
        }
      }
    },
    copyQrText(event) {
      event.preventDefault();
      event.stopPropagation();
      try {
        copyToClipboard(this.qrCode.text);
        showNotificationSuccess("Código copiado para a área de transferência");
      } catch (error) {
        console.error("Erro ao copiar o código QR:", error);
      }
    },
    // async simulate() {
    //   const body = {
    //     id: this.qrCode.idCob,
    //     value: this.formDataBuyChips.valueChips,
    //     status: "paid",
    //     paid_at: "2024-04-25T21:08:03.350000+00:00",
    //     hash: this.qrCode.hash,
    //     description: "Recarga de saldo",
    //   };
    //   await simulatePayment(body);
    //   showNotificationSuccess("Pagamento simulado com sucesso");
    // },
  },
};
</script>
<style src="../../styles/QrCode.css"></style>
<style src="../../styles/Steps.css"></style>