<template>
    
      <component :is="currentStepComponent"></component>
</template>


<script>
import NewPassword from "./NewPassword.vue";
import PasswordConfirmation from "./PasswordConfirmation.vue";
import RecoverPassword from "./RecoverPassword.vue";
import VerifyCode from "./VerifyCode.vue";

export default {
  props: ["step"],
  components: {
    RecoverPassword,
    VerifyCode,
    NewPassword,
    PasswordConfirmation
  },
  data() {
    return {};
  },

  computed: {
    currentStepComponent() {
      console.log(this.step);
      switch (this.step) {
        case 1:
          return "RecoverPassword";
        case 2:
          return "VerifyCode";
        case 3:
          return "NewPassword";
        case 4:
          return "PasswordConfirmation";
        default:
          return "RecoverPassword";
      }
    },
  },

  methods: {},
};
</script>

