<template>
  <v-row>
    <v-col cols="6" offset="3" class="d-sm-none pb-0">
      <img src="../../assets/logoFichasAzul.png" class="img-fichas-azul-deposit" />
    </v-col>
    <v-col cols="12" md="9" style="margin: auto">
      <p v-if="!userLogged" class="text-buy">
        Estamos quase lá! Agora selecione o
        <span class="extra-bold">valor desejado </span> para começar a
        <span class="extra-bold">jogar!</span>
      </p>
      <p v-else class="text-buy pt-0">
        Selecione o
        <span class="extra-bold">valor desejado </span> para continuar
      </p>
    </v-col>
    <v-col cols="12"> </v-col>
    <div class="box-chips mt-6 mb-2">
      <div
        v-for="option in options"
        :key="option.id"
        :class="{
          'option-selected': selectedOptionId === option.id,
        }"
        @click="selectOption(option)"
        class="chip-deposit mb-8"
      >
        <p v-if="option.id === 2" class="indicated">MAIS INDICADO</p>
        <img
          src="../../assets/ficha.png"
          :alt="option.alt"
          class="img-ficha-deposit"
        />
        <p class="text-value">{{ option.text }}</p>
      </div>
    </div>

    <v-col
      cols="12"
      sm="10"
      md="8"
      class="text-center mt-0"
      style="margin: auto"
    >
      <v-text-field
        v-model="formDataBuyChips.valueChips"
        prepend-inner-icon="mdi-currency-brl"
        label="Digite aqui outro valor"
        variant="outlined"
        color="#0e0d5e"
        rounded
        @input="deselectOption"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6" offset="3" style="text-align: center" class="pt-0">
      <button v-if="!loading" @click="purchase()" class="button-buy ">
        <div class="text-wrapper">COMPRAR</div>
      </button>
      <v-progress-circular
        v-else-if="loading"
        indeterminate
        color="#0D3A50"
        :size="33"
        class="loading-spinner"
      />
    </v-col>
    <v-col cols="3" class="d-none d-sm-block">
      <img src="../../assets/logoFichasAzul.png" class="img-fichas-azul-deposit" />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import rules from "../../utils/rules";
// import Spinner from "@/components/SpinnerLoading.vue";

export default {
  data() {
    return {
      rules: rules,
      selectedOptionId: 2,
      options: [
        {
          id: 1,
          alt: "Opção 1",
          value: "20",
          text: "R$ 20,00",
        },
        {
          id: 2,
          alt: "Opção 2",
          value: "50",
          text: "R$ 50,00",
        },
        {
          id: 3,
          alt: "Opção 3",
          value: "100",
          text: "R$ 100,00",
        },
      ],
    };
  },
  computed: {
    ...mapState("purchase", ["formDataBuyChips", "loading"]),
    ...mapState("login", ["userLogged"]),
  },
  components: {
    // Spinner,
  },
  methods: {
    ...mapActions("purchase", ["buyChips"]),

    purchase() {
      this.buyChips();
    },
    selectOption(option) {
      this.selectedOptionId = option.id;
      this.formDataBuyChips.valueChips = option.value;
    },
    deselectOption() {
      this.selectedOptionId = null;
    },
  },
};
</script>
<style src="../../styles/BuyChips.css"></style>