<template>
  <div
    class="recover"
    :style="{ backgroundImage: `url(${style.backgroundImg})` }"
  >
    <!-- <button
      class="larger-button-non-validation"
      @click="nextStepNonValidation()"
    >
      AVANÇAR SEM VALIDAÇÃO
    </button> -->
    <RecoverComponent :totalSteps="totalSteps" />
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import RecoverComponent from "../components/recover/RecoverComponent.vue";

export default {
  components: {
    RecoverComponent,
  },
  computed: {
    ...mapState("configs", ["style"]),
  },
  data() {
    return {
      totalSteps: 2,
    };
  },
  methods: {
    ...mapActions("purchase", ["saveStepNonValidation"]),
  },
};
</script>

<style src="../styles/Recover.css"></style>