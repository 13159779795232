<template>
  <v-row>
    <v-col cols="12" md="9" class="mx-auto pt-0">
      <p class="text-confirmation mb-0">
        CONFIRA SEUS DADOS E EM SEGUIDA CLIQUE EM CONCLUIR
      </p>
    </v-col>
    <v-col cols="12" lg="6" sm="6"  class="col-img ml-10 mt-3">
      <v-card elevation="6" class="info-withdraw mt-4 pa-6">
        <p class="title-card mb-3"><strong>Detalhes da Transação</strong></p>
        <v-divider
          :thickness="1"
          class="border-opacity-100"
          color="#C1C1C1"
        ></v-divider>
        <p class="text-card mt-3">
          <strong>Valor:</strong>
          R$ {{ formDataWithdraw.value }}
        </p>
        <p class="text-card mt-3">
          <strong>Chave Pix:</strong>
          {{ formDataWithdraw.key }}
        </p>
        <!-- <p class="text-card mt-3">
          <strong>Banco:</strong>
        </p> -->
        <p class="text-card mt-3">
          <strong>Clube:</strong>
          {{ formDataWithdraw.club }}
        </p>
        <p class="text-card mt-3">
          <strong>Id Jogador:</strong>
          {{ formDataWithdraw.idPlayer }}
        </p>
        <p class="text-card mt-3">
          <strong>Plataforma:</strong>
          {{ getPlatformName(formDataWithdraw.platformId) }}
        </p>
      </v-card>
    </v-col>
    <v-col cols="12" lg="5" md="4" sm="4" class="qr-button-column  mt-3">
      <img class="d-none d-sm-inline img-confirmation " src="../../assets/xtremeFichas.png" alt="" />
      <img class="d-sm-none img-confirmation" src="../../assets/fichasXtreme.png" alt="" />
      <v-col></v-col>
      <button
        v-if="!loading"
        @click="startWithdraw()"
        class="button-withdraw mt-5"
      >
        <div class="text-wrapper">CONCLUIR</div>
      </button>
      <v-progress-circular
        v-else-if="loading"
        indeterminate
        color="#0D3A50"
        :size="50"
        class="loading-spinner"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import rules from "../../utils/rules";
import { getPlatform } from "@/services/platforms/index.js";
import { showNotificationSuccess } from "@/services/notifications/index.js";

export default {
  data() {
    return {
      rules: rules,
      typesKeys: [
        { text: "CPF", value: "cpf" },
        { text: "CNPJ", value: "cnpj" },
        { text: "E-mail", value: "email" },
        { text: "Telefone", value: "phone" },
        { text: "Aleatória", value: "random" },
      ],
    };
  },

  computed: {
    ...mapState("withdraw", ["formDataWithdraw", "loading"]),
  },

  methods: {
    ...mapActions("withdraw", ["createWithdraw"]),
    ...mapMutations("withdraw", ["SET_CURRENT_STEP"]),
    async startWithdraw() {
      let created = await this.createWithdraw();
      if (created) {
        this.$router.push("/menu");
        await this.awaitTime(500);
        showNotificationSuccess("Saque realizado com sucesso!");

        this.SET_CURRENT_STEP(1);
      }
    },
    async awaitTime(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    getPlatformName(platformId) {
      return getPlatform(platformId);
    },
    async validate() {
      return await this.$refs.step1Form.validate();
    },
  },
};
</script>
<style src="../../styles/ConfirmationWithdraw.css"></style>