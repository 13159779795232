import { URL_API_OLD } from '@/config/apiOld';
import axios from "axios";

export async function sendCodeReset(body) {
  const url = `${URL_API_OLD}/users/send_code_reset_password`;
  let response = await axios.post(url, body);
  return response.data;
}

export async function validateCodeReset(body) {
  const url = `${URL_API_OLD}/users/validate_code_reset_password`;
  console.log("sendCodeReset -> url", url);
  let response = await axios.post(url, body);
  return response.data;

}

export async function setNewPassword(body) {
  const url = `${URL_API_OLD}/users/set_new_password`;
  let response = await axios.post(url, body);
  return response.data;
}