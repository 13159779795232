<template>
  <v-form ref="formRecover" v-model="isValid" lazy-validation>
    <v-row>
      <v-col cols="12" md="10" style="margin: auto">
        <p class="text-recover">
          <span class="extra-bold">RECUPERAÇÃO DE SENHA </span>
        </p>
        <p class="subtext-recover mt-4">
          Informe o e-mail cadastrado para recuperar sua senha!
        </p>
      </v-col>

      <v-col cols="12" sm="10" class="text-center" style="margin: auto">
        <div class="field-description"><span class="ml-1">EMAIL*</span></div>
        <v-text-field
          ref="emailField"
          v-model="formDataRecover.email"
          variant="outlined"
          color="#0e0d5e"
          rounded
          :rules="[rules.email, rules.required]"
          class="mt-1 mb-1"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" offset="3" style="text-align: center" class="pt-0">
        <button v-if="!loading" @click="nextStepRecover()" class="button-next">
          <div class="text-wrapper">CONTINUAR</div>
        </button>
        <v-progress-circular
          v-else-if="loading"
          indeterminate
          color="#0D3A50"
          :size="33"
          class="loading-spinner"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import rules from "../../utils/rules";
import { mapFields } from "vuex-map-fields";
import { showNotificationError } from "@/services/notifications/index.js";
// import Spinner from "@/components/SpinnerLoading.vue";

export default {
  data() {
    return {
      rules: rules,
      isValid: false,
    };
  },
  computed: {
    ...mapState("recover", ["formDataRecover", "loading"]),
    ...mapFields("recover", ["formDataRecover.email"]),
    ...mapState("login", ["userLogged"]),
  },
  components: {
    // Spinner,
  },
  methods: {
    ...mapActions("recover", ["buyChips", "sendCode"]),
    ...mapMutations("recover", ["SET_NEXT_CURRENT_STEP"]),

    async nextStepRecover() {
      await this.$refs.formRecover.validate();
      if (this.isValid) {
        this.sendCode();
      } else {
        showNotificationError("Preencha o campo de email corretamente");
      }
    },
  },
};
</script>
<style src="../../styles/Recover.css"></style>