import axios from "axios";

const axiosAuth = axios.create();
axiosAuth.interceptors.request.use(
  (config) => {
    let user = localStorage.getItem('userLogged');
    if (user) {
        user = JSON.parse(user);
        let token = user.accessToken;
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default axiosAuth;