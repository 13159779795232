<template>
  <v-app-bar app class="custom-navbar">
    <v-container fluid class="mt-8">
      <v-row class="align-center no-gutters">
        <!-- Logo do WhatsApp no canto esquerdo para dispositivos móveis -->
        <v-col cols="2" class="d-md-none align-center justify-start pa-0">
          <v-toolbar-title class="wpp-container">
            <img
              class="logo-wpp"
              src="../assets/logo-wpp.png"
              alt="Other Image"
            />
          </v-toolbar-title>
        </v-col>
        <!-- Logo centralizado para dispositivos móveis e à esquerda para dispositivos maiores -->
        <v-col cols="8" md="4" class="logo-container">
          <v-toolbar-title>
            <img src="../assets/logoXtreme.svg" alt="Logo" class="logo" />
          </v-toolbar-title>
        </v-col>
        <!-- Botão do menu sanduíche para dispositivos móveis -->
        <v-col cols="2" class="d-md-none align-center justify-end pa-0">
          <v-btn icon @click="drawer = !drawer" class="large-menu-btn">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-col>
        <!-- Botões de login/depositar/sacar para dispositivos maiores -->
        <v-col md="8" class="d-none d-md-flex justify-end">
          <div class="v-btn-container">
            <v-btn v-if="!userLogged" text @click="login">Login</v-btn>
            <v-btn v-if="userLogged" text @click="depositar">Depositar</v-btn>
            <v-btn v-if="userLogged" text @click="sacar">Sacar</v-btn>
            <v-btn v-if="userLogged" text @click="exit">Logout</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Drawer de navegação para dispositivos móveis -->
  </v-app-bar>
  <v-navigation-drawer
    class="list-menu"
    v-model="drawer"
    app
    temporary
    location="right"
  >
    <v-list class="pt-4">
      <v-list-item prepend-icon="mdi-login" v-if="!userLogged" @click="login">
        <v-list-item-title class="options-menu">Login</v-list-item-title>
      </v-list-item>

      <v-list-item
        prepend-icon="mdi-cash-plus"
        v-if="userLogged"
        @click="depositar"
      >
        <v-list-item-title class="options-menu">Depositar</v-list-item-title>
      </v-list-item>
      <v-list-item
        prepend-icon="mdi-cash-fast"
        v-if="userLogged"
        @click="sacar"
      >
        <v-list-item-title class="options-menu">Sacar</v-list-item-title>
      </v-list-item>
      <v-list-item prepend-icon="mdi-logout" v-if="userLogged" @click="exit">
        <v-list-item-title class="options-menu">Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions,mapMutations, mapState } from "vuex";
export default {
  name: "NavBar",
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapState("configs", ["style"]),
    ...mapState("login", ["userLogged", "playerId"]),
  },
  methods: {
    ...mapMutations("purchase", {
      setCurrentStepPurchase: "SET_CURRENT_STEP",
    }),
    ...mapMutations("withdraw", {
      setCurrentStepWithdraw: "SET_CURRENT_STEP",
    }),
    ...mapActions("login", ["logout"]),
    depositar() {
      this.setCurrentStepPurchase(4);
      this.$router.push("/buy-chips");
    },
    sacar() {
      this.setCurrentStepWithdraw(1);
      this.$router.push("/withdraw");
    },
    login() {
      this.$router.push("/login");
    },
    exit() {
      this.logout();
    },
  },
};
</script>

<style scoped>
.options-menu {
  font-size: 20px;
  color: #0d3a50;
  text-align: left;
}
.list-menu {
  background: linear-gradient(to right, #4facfe, #00f2fe);
}
.custom-navbar {
  background: linear-gradient(to right, #4facfe, #00f2fe);
  height: 80px;
}

.logo {
  max-height: 50px;
  max-width: 160px;
  margin: auto;
}

.logo-container {
  text-align: center;
}

.wpp-container {
  text-align: right;
}

.logo-wpp {
  max-height: 42px;
  max-width: 42px;
  margin-left: auto;
}

.v-btn-container {
  display: flex;
  align-items: center;
}

.v-btn-container .v-btn {
  font-size: 16px;
}

.large-menu-btn {
  font-size: 30px;
  color: #0d3a50;
}

@media (min-width: 960px) {
  .logo {
    max-height: 70px;
    max-width: 230px;
  }

  .logo-container {
    text-align: left;
  }

  .logo-wpp {
    display: none;
  }
}

@media (max-width: 959px) {
  .logo {
    max-height: 57px;
    max-width: 175px;
  }

  .v-btn-container {
    display: none;
  }

  .v-navigation-drawer {
    top: 80px;
  }

  .large-menu-btn {
    align-self: center;
  }
}

@media (max-width: 600px) {
  .large-menu-btn {
    top: -12px;
  }
}
</style>
