<template>
  <v-row>
    <v-col cols="12">
      <p class="text-select-withdraw">
        SELECIONE UMA CONTA PARA CONTINUAR COM O RECEBIMENTO
      </p>
    </v-col>
  </v-row>
  <v-row>
    <v-col v-if="formDataSelectAccount.accounts.length == 0" cols="12">
      <v-sheet
        border="lg opacity-12"
        rounded="xl"
        class="v-sheet-account mx-auto"
        max-width="420"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="2" class="d-flex align-center">
              <v-icon :size="sizeIcon" color="#0D3A50" class="mr-1"
                >mdi-information-outline</v-icon
              >
              <v-divider
                vertical
                :thickness="2"
                class="border-opacity-100"
                color="black"
                role="presentation"
              ></v-divider>
            </v-col>
            <v-col cols="8" class="pl-0">
              <p class="text-account-key">NENHUMA CHAVE CADASTRADA</p>
              <p class="text-account-key-2">CADASTRE UMA PARA CONTINUAR</p>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center">
              <v-icon :size="sizeIcon" color="#0D3A50">mdi-key-plus</v-icon>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-col>
    <v-col cols="12" class="mx-auto">
      <v-sheet
        border="lg opacity-12"
        rounded="xl"
        class="mx-auto mt-2 v-sheet-account"
        max-width="420"
        v-for="(account, index) in formDataSelectAccount.accounts"
        :key="index"
        @click="selectAccount(account)"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="2" class="d-flex align-center">
              <v-icon :size="sizeIcon" color="#0D3A50" class="mr-1"
                >mdi-information-outline</v-icon
              >
              <v-divider
                vertical
                :thickness="2"
                class="border-opacity-100"
                color="black"
                role="presentation"
              ></v-divider>
            </v-col>
            <v-col cols="8" class="pl-0">
              <p class="text-account-key">{{ account.pixAccountNickname }}</p>
              <p class="text-account-key-2">{{ account.key }}</p>
              <p class="text-account-key-2">CHAVE {{ account.type }}</p>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center">
              <v-icon :size="sizeIcon" color="#0D3A50"
                >mdi-chevron-right</v-icon
              >
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-col>
  </v-row>
  <v-row class="mt-10">
    <v-col cols="12" class="d-flex justify-center ">
      <button
        v-if="!loadingStep"
        @click="createKeyPix()"
        class="button-account"
      >
        <div class="text-wrapper-withdraw">CADASTRAR CHAVE</div>
      </button>
      <v-progress-circular
        v-else-if="loadingStep"
        indeterminate
        color="primary"
        class="loading-spinner"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import rules from "../../utils/rules";

export default {
  data() {
    return {
      rules: rules,
      sizeIcon: 30,
    };
  },
  computed: {
    ...mapState("withdraw", ["loading", "formDataSelectAccount"]),
  },
  components: {
    // Spinner,
  },
  methods: {
    ...mapActions("withdraw", ["getInfoToWithdraw"]),
    ...mapMutations("withdraw", ["SET_NEXT_CURRENT_STEP"]),
    handleResize() {
      this.$forceUpdate();
    },
    updateIconClass() {
      if (window.innerWidth <= 600) {
        this.sizeIcon = 20;
      } else if (window.innerWidth <= 959) {
        this.sizeIcon = 35;
      } else {
        this.sizeIcon = 40;
      }
    },

    selectAccount(account) {
      this.getInfoToWithdraw(account);
    },

    createKeyPix() {
      this.SET_NEXT_CURRENT_STEP();
    },
  },
  mounted() {
    this.updateIconClass();
    window.addEventListener("resize", this.handleResize);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style src="../../styles/SelectAccount.css"></style>