let BASE_URL_API = 'http://localhost:3050'
let WS = 'ws://localhost:3050'

const isLocalHost = (url) => url.indexOf("127.0.0.1") != -1 || url.indexOf("localhost") != -1 || url.indexOf("192.168") != -1

const isHomolog = (url) => url.indexOf("homolog") != -1

BASE_URL_API = isLocalHost(window.location.href) ? 'http://localhost:3050' 
    :  isHomolog(window.location.href) ? 'https://api.homologacao.fichas24horas.com.br'
    : 'https://api.fichas24horas.com.br';

    WS = isLocalHost(window.location.href) ? 'ws://localhost:3050' 
    :  isHomolog(window.location.href) ? 'wss://api.homologacao.fichas24horas.com.br'
    : 'wss://api.fichas24horas.com.br'; 

export const URL_API_OLD = `${BASE_URL_API}`;
export const WS_URL_OLD = `${WS}`;
