<template>
  <v-form ref="step3Form">
    <v-row class="step-3">
      <v-col cols="12" class="pa-0 mt-2">
        <v-text-field
          max-width="860px"
          v-model="formDataStep2.email"
          label="Email"
          readonly
          disabled
          rounded=""
          variant="outlined"
          class="mx-auto"
          bg-color="#E8E8E8"
        ></v-text-field>
      </v-col>

      <v-col
        v-for="(number, index) in formDataStep3.confirmationCode"
        :key="index"
        cols="3"
        sm="2"
      >
        <v-text-field
          class="code-field mx-auto mb-1"
          bg-color="#E8E8E8"
          color="#0e0d5e"
          rounded
          variant="outlined"
          inputmode="numeric"
          v-model="formDataStep3.confirmationCode[index]"
          ref="codeFields"
          @input="focusNextField(index)"
          maxlength="1"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
  <v-row>
    <button @click="pasteCode" class="paste-code mb-2">
      <i class="mdi mdi-content-paste"></i> COLAR
    </button>
  </v-row>

  <div class="warning-step mt-4">
    <p class="text-validation mb-3">
      <span>
        ⚠️ <strong class="extra-bold">AVISO</strong>: Caso ainda não tenha
        recebido, verifique em <strong class="extra-bold">SPAM</strong>, ou
        <a href="google.com" class="extra-bold"> CLIQUE AQUI </a> para reenviar!
      </span>
      <br />
    </p>
  </div>

  <div class="observation mx-auto">
    *O e-mail de confirmação que você receberá, será enviado pela Liga Suprema.
  </div>
</template>

<script>
import { mapState } from "vuex";
import rules from "../../utils/rules";
import { showNotificationError } from "@/services/notifications/index.js";

export default {
  data() {
    return {
      rules: rules,
    };
  },
  computed: {
    ...mapState("form", ["formDataStep2", "formDataStep3"]),
  },

  methods: {
    pasteCode() {
      navigator.clipboard
        .readText()
        .then((text) => {
          if (text.trim().length === 4 && /^\d+$/.test(text)) {
            this.formDataStep3.confirmationCode = text.split("");
          } else {
            showNotificationError(
              "O código colado não está no formato correto. Por favor, tente novamente."
            );
          }
        })
        .catch((err) => {
          console.error("Erro ao acessar a área de transferência: ", err);
          showNotificationError(
            "Não foi possível acessar a área de transferência. Por favor, tente novamente."
          );
        });
    },
    focusNextField(index) {
      if (
        index < 3 &&
        this.formDataStep3.confirmationCode[index].length === 1
      ) {
        const nextField = this.$refs.codeFields[index + 1];
        if (nextField) {
          nextField.focus();
        }
      }
    },

    async validate() {
      console.log("validando formulário");
      return await this.$refs.step3Form.validate();
    },
    handlePaste(event) {
      if (event.ctrlKey && (event.key === "v" || event.key === "V")) {
        event.preventDefault();
        this.pasteCode();
      }
    },
  },
  mounted() {
    document.addEventListener("keydown", this.handlePaste);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handlePaste);
  },
};
</script>
<style src="../../styles/Steps.css"></style>