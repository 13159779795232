<template>
  <v-row>
    <v-col cols="12" style="margin: auto">
      <v-icon :size="sizeIcon"> mdi-check-circle-outline </v-icon>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="10" style="margin: auto">
      <div class="new-password">
        <p class="password-confirmation">Senha cadastrada com sucesso!</p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import rules from "../../utils/rules";

export default {
  data() {
    return {
      rules: rules,
      show1: false,
      show2: true,
      sizeIcon: 200,
    };
  },
  computed: {
    ...mapState("recover", ["formDataRecover", "loading"]),
    ...mapFields("recover", ["formDataRecover.token"]),
    ...mapState("login", ["userLogged"]),
  },
  components: {
    // Spinner,
  },
  methods: {
    updateIconClass() {
      if (window.innerWidth <= 600) {
        this.sizeIcon = 120;
      } else if (window.innerWidth <= 959) {
        this.sizeIcon = 150;
      } else {
        this.sizeIcon = 200;
      }
    },
    ...mapActions("recover", ["buyChips"]),

    purchase() {
      this.buyChips();
    },
  },
};
</script>
<style src="../../styles/Recover.css"></style>