<template>
  <div class="spinner"></div>
</template>

<script>
export default {
  name: 'SpinnerLoading'
};
</script>

<style src="../styles/Spinner.css"></style>
