import { WS_URL } from "@/config/api";

let ws = null;
let action = null;
let url_socket = `${WS_URL}`

const onMessage = (message) => {
  if (!action) return console.log('Action not set');
  action(JSON.parse(message.data));
}

export const startSocket = (hash) => {
  ws = new WebSocket(url_socket + "?hash=" + hash);
  ws.onmessage = (message) => {
    onMessage(message);
  }
}

export const setActionFromReceivedMessage = (_action) => {
  action = _action;
}

export const disconnectSocket = () => {
  ws.close();
}