<template>
    <v-form >
      <component :is="currentStepComponent"></component>
    </v-form>
</template>


<script>
import WithdrawChipsComponent from "@/components/withdraw/WithdrawChipsComponent.vue";
import SelectAccountComponent from "@/components/withdraw/SelectAccountComponent.vue";
import RegisterKeyPixComponent from "@/components/withdraw/RegisterKeyPixComponent.vue";
import ConfirmationWithdrawComponent from "@/components/withdraw/ConfirmationWithdrawComponent.vue";

export default {
  props: ["step"],
  components: {
    WithdrawChipsComponent,
    SelectAccountComponent,
    RegisterKeyPixComponent,
    ConfirmationWithdrawComponent,
  },
  data() {
    return {};
  },
  computed: {
    currentStepComponent() {
      switch (this.step) {
        case 1:
          return "WithdrawChipsComponent";
        case 2:
          return "SelectAccountComponent";
        case 3:
          return "RegisterKeyPixComponent";
        case 4:
          return "ConfirmationWithdrawComponent";
        default:
          return "WithdrawChipsComponent";
      }
    },
  },

  methods: {},
};
</script>

<style src="../../styles/Steps.css"></style>
