import axios from 'axios';
import { URL_API } from '@/config/api';

export async function getConfigs(clubDomain) {
  const url = `${URL_API}/config_clubs/${clubDomain}`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar dados do backend:', error);
    throw error; 
  }
}

