<template>
  <v-row class="box-text mt-2 ">
    <v-col cols="2" class="animation pa-0 ">
      <Vue3Lottie :animationData="ExchangeJson" :height="animationSize.height"
        :width="animationSize.width" />
    </v-col>
    <v-col cols="8" class="pa-0 d-flex align-center justify-center" >
      <div class="payment-text">
        <p class="mb-2"><strong style="font-weight: 900">PAGAMENTO REALIZADO! </strong></p>
        <span >Suas fichas já estão esperando por você!</span>
      </div>
    </v-col>
    <v-col cols="2" class="animation pa-0">
      <Vue3Lottie :animationData="SpadesJson" :height="animationSize.height"
        :width="animationSize.width" />
    </v-col>
  </v-row>

  <div class="text-download mb-5">
    <p class="pa-3">
      Baixe o <strong>APP Suprema</strong>, faça
      <strong>login com seus dados</strong> (usuário e senha) e utilize suas
      fichas. Comece sua jornada no
      <strong> melhor clube de poker do Brasil! </strong>
    </p>
  </div>

  <p class="download mb-5">DOWNLOAD</p>

  <v-row class="button-container">
    <v-col cols="6" lg="4" sm="4" md="4" class="pa-0 mb-2" >
      <a
        href="https://play.google.com/store/apps/details?id=com.opt.supremapoker&hl=pt_BR&gl=US"
        target="_blank"
      >
        <img
          class="img-shop"
          src="../../assets/logo-google.png"
          alt="Download Google Play"
        />
      </a>
    </v-col>
    <v-col cols="6" lg="4" sm="4" md="4" class="pa-0">
      <a
        href="https://apps.apple.com/br/app/suprema-poker/id1583176410"
        target="_blank"
      >
        <img
          class="img-shop"
          src="../../assets/logo-app-store.png"
          alt="Download App Store"
        />
      </a>
    </v-col>
    <v-col cols="12" lg="4" sm="4" md="4" class="pa-0">
      <a
        href="https://www.supremapoker.net/download/supremapoker.exe"
        target="_blank"
      >
        <img
          class="img-shop"
          src="../../assets/logo-windows.png"
          alt="Download Windows"
        />
      </a>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
// import { Vue3Lottie } from "vue3-lottie";
import ExchangeJson from "../../assets/exchange.json";
import SpadesJson from "../../assets/spades.json";
export default {
  data() {
    return {
      animationSize: { width: 100, height: 100 },
      ExchangeJson,
      SpadesJson,
    };
  },
  computed: {
    ...mapState("configs", ["style"]),
    ...mapState("login", ["userLogged"]),

  },
  components: {
    // Vue3Lottie,
  },
  methods: {
    updateAnimationSize() {
      const xs = 600;
      const sm = 960;
      const md = 1280;
      const lg = 1920;

      const width = window.innerWidth;

      if (width < xs) {
        this.animationSize = { width: 50, height: 50 }; // Tamanho para xs
      } else if (width < sm) {
        this.animationSize = { width: 75, height: 75 }; // Tamanho para sm
      } else if (width < md) {
        this.animationSize = { width: 100, height: 100 }; // Tamanho para md
      } else if (width < lg) {
        this.animationSize = { width: 100, height: 100 }; // Tamanho para lg
      } else {
        this.animationSize = { width: 100, height: 100 }; // Tamanho para xl
      }
    },
  },
  mounted() {
    this.updateAnimationSize();
    window.addEventListener("resize", this.updateAnimationSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateAnimationSize);
  },
};
</script>

<style src="../../styles/Payment.css"></style>

