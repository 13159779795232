
import { createStore } from 'vuex'
import configsModule from './modules/configs'
import loginModule from './modules/login'
import formModule from './modules/form'
import purchaseModule from './modules/purchase'
import withdrawModule from './modules/withdraw'
import recoverModule from './modules/recover'

export default createStore({
  modules: {
    configs: configsModule,
    login: loginModule,
    form: formModule,
    purchase: purchaseModule,
    withdraw: withdrawModule,
    recover: recoverModule
  }
})
