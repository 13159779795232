<template>
  <div
    class="register"
    :style="{ backgroundImage: `url(${style.backgroundImg})` }"
  >
    <!-- <button
      class="larger-button-non-validation"
      @click="nextStepNonValidation()"
    >
      AVANÇAR SEM VALIDAÇÃO
    </button> -->
    <WithdrawComponent :totalSteps="totalSteps" />
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import WithdrawComponent from "@/components/withdraw/WithdrawComponent.vue";

export default {
  components: {
    WithdrawComponent,
  },
  computed: {
    ...mapState("configs", ["style"]),
  },
  data() {
    return {
      totalSteps: 6,
    };
  },
  methods: {
    ...mapActions("withdraw", ["saveStepNonValidation"]),
  },
};
</script>

<style src="../styles/Register.css"></style>